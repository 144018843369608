import { render, staticRenderFns } from "./MSPTS320M.vue?vue&type=template&id=fb8d0f74&scoped=true&"
import script from "./MSPTS320M.vue?vue&type=script&lang=js&"
export * from "./MSPTS320M.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb8d0f74",
  null
  
)

export default component.exports